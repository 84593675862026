import React, { FC } from 'react';
import { IconsTypes } from 'types/baseTypes';

export const LogoIcon: FC<IconsTypes> = ({ width, height, style }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 386 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M195.142 29.5722H205.038C203.851 39.0695 196.379 45.5 186.631 45.5C176.537 45.5 168.57 37.3877 168.57 26.9505C168.57 16.5628 176.537 8.5 186.631 8.5C196.379 8.5 203.9 14.9799 205.038 24.3783H195.142C194.4 19.9265 191.084 16.8596 186.73 16.8596C181.485 16.8596 178.17 20.9158 178.17 26.9505C178.17 33.0348 181.485 37.1404 186.73 37.1404C191.035 37.1404 194.35 34.0735 195.142 29.5722Z'
        fill='#373748'
      />
      <path
        d='M124.528 44.758H135.909L126.953 31.4519C131.307 29.6217 134.474 26.1096 134.474 21.1136C134.474 13.4465 128.882 9.24198 119.382 9.24198H104.834V16.957H115.834V16.9586H118.986C123.291 16.9586 125.171 18.5909 125.171 21.1136C125.171 23.6364 123.291 25.2193 118.986 25.2193L104.834 25.2188V44.758H114.186V32.9358H117.254L124.528 44.758Z'
        fill='#373748'
      />
      <path
        d='M165.536 36.6457H149.751V30.5615H163.754V22.4492L140.399 22.4492V44.758H165.536V36.6457Z'
        fill='#373748'
      />
      <path
        d='M165.189 17.3543H150.747V17.3499H140.399V9.24198H165.189V17.3543Z'
        fill='#373748'
      />
      <path
        d='M219.238 44.758V30.5615H232.35V44.758H241.702V9.24198H232.35V22.4492H219.238V9.24198H209.886V44.758H219.238Z'
        fill='#373748'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M281.891 44.758H271.797L269.62 38.1791H257.151L254.924 44.758H245.473L258.635 9.24198H268.878L281.891 44.758ZM263.484 19.5307L259.625 30.8088H267.195L263.484 19.5307Z'
        fill='#373748'
      />
      <path
        d='M305.348 44.758H316.729L307.773 31.4519C312.127 29.6217 315.294 26.1096 315.294 21.1136C315.294 13.4465 309.702 9.24198 300.202 9.24198H285.654V16.957H297.372V16.9586H299.806C304.111 16.9586 305.991 18.5909 305.991 21.1136C305.991 23.6364 304.111 25.2193 299.806 25.2193L285.654 25.2188V44.758H295.006V32.9358H298.074L305.348 44.758Z'
        fill='#373748'
      />
      <path
        d='M337.152 24.9719H355.708C356.994 36.9425 349.374 45.5 337.597 45.5C327.256 45.5 319.141 37.8824 319.141 26.8021C319.141 16.3155 326.86 8.5 337.301 8.5C346.207 8.5 352.788 13.6444 355.262 21.6083H344.178C343.288 18.8877 340.665 16.8596 337.053 16.8596C332.451 16.8596 328.74 20.0749 328.74 27.0495C328.74 32.9853 331.66 37.5856 338.092 37.5856C342.15 37.5856 344.673 35.3102 345.712 32.3422H337.152V24.9719Z'
        fill='#373748'
      />
      <path
        d='M385.834 36.6457H370.05V30.5615H384.053V22.4492L360.698 22.4492V44.758H385.834V36.6457Z'
        fill='#373748'
      />
      <path
        d='M360.698 17.3499V9.24198H385.488V17.3543H370.98V17.3499H360.698Z'
        fill='#373748'
      />
      <path
        d='M126.159 65.8489H115.422V70.0321H120.375C119.774 71.7166 118.314 73.008 115.966 73.008C112.244 73.008 110.555 70.3971 110.555 67.0281C110.555 63.0695 112.702 61.2447 115.365 61.2447C117.455 61.2447 118.972 62.3957 119.488 63.9398H125.901C124.47 59.4198 120.662 56.5 115.508 56.5C109.467 56.5 105 60.9358 105 66.8877C105 73.1765 109.696 77.5 115.68 77.5C122.494 77.5 126.903 72.643 126.159 65.8489Z'
        fill='#373748'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M144.023 77.0789H149.864L142.334 56.9211H136.407L128.791 77.0789H134.26L135.548 73.3449H142.764L144.023 77.0789ZM136.98 69.1618L139.213 62.7607L141.361 69.1618H136.98Z'
        fill='#373748'
      />
      <path
        d='M168.648 77.0789H163.924L159.515 65.4559V77.0789H154.905V56.9211H161.29L166.472 70.5936L171.626 56.9211H178.011V77.0789H173.086V65.3155L168.648 77.0789Z'
        fill='#373748'
      />
      <path
        d='M199.435 72.4746H190.302V69.0214H198.405V64.4171L184.89 64.4161V77.0789H199.435V72.4746Z'
        fill='#373748'
      />
      <path
        d='M186.89 61.5303C185.786 61.5303 184.89 60.6348 184.89 59.5303V56.9211H199.235V61.5254H190.302V61.5303H186.89Z'
        fill='#373748'
      />
      <path
        d='M216.388 65.0909C220.053 65.7928 222 67.5615 222 70.4813C222 74.6364 218.621 77.3596 213.267 77.3596C207.913 77.3596 204.477 74.5802 204.105 70.1163H209.574C209.602 72.0254 210.919 73.2326 213.153 73.2326C215.014 73.2326 216.245 72.5307 216.245 71.2674C216.245 70.369 215.357 69.7513 214.155 69.5267L210.404 68.8249C207.111 68.2072 204.935 66.3262 204.935 63.0695C204.935 59.3075 208.314 56.6684 212.924 56.6684C217.82 56.6684 221.341 59.4198 221.656 63.7714H216.188C216.045 61.9465 214.785 60.7674 212.981 60.7674C211.378 60.7674 210.404 61.6096 210.404 62.7045C210.404 63.631 211.32 64.1364 212.408 64.3329L216.388 65.0909Z'
        fill='#373748'
      />
      <g clipPath='url(#clip0_230_632)'>
        <path
          d='M42.5311 17.4637C37.8485 17.4637 34.0524 21.2597 34.0524 25.9424V57.5867L32.0963 58.7161C28.041 61.0574 22.8555 59.668 20.5141 55.6127C18.1981 51.6012 17.0989 47.2211 17.1021 42.8999H17.095V25.9424C17.095 11.8944 28.4831 0.506226 42.5311 0.506226C65.9445 0.506226 84.9248 19.4865 84.9248 42.8999C84.9248 50.5054 82.922 57.6431 79.4151 63.8145C86.2167 51.6906 82.0197 36.3169 69.9463 29.3464L55.175 20.8181L55.1726 20.8224C51.4486 18.6855 47.1326 17.4637 42.5311 17.4637Z'
          fill='url(#paint0_linear_230_632)'
        />
        <path
          d='M20.5117 55.6294C22.853 59.6847 28.0385 61.0741 32.0938 58.7328L59.4986 42.9106L61.4548 44.04C65.5101 46.3814 66.8995 51.5669 64.5582 55.6222C62.2422 59.6336 58.9985 62.7756 55.2546 64.9335L55.2581 64.9397L40.5726 73.4184C28.4066 80.4424 12.8501 76.274 5.8261 64.1081C-5.88057 43.8315 1.06669 17.904 21.3432 6.19733C27.9298 2.39457 35.1127 0.560154 42.2107 0.51157C28.3103 0.683119 17.0948 12.0047 17.0948 25.9458L17.0948 43.0023L17.0997 43.0022C17.1111 47.2957 18.211 51.6444 20.5117 55.6294Z'
          fill='url(#paint1_linear_230_632)'
        />
        <path
          d='M64.5766 55.615C66.9179 51.5596 65.5284 46.3741 61.4731 44.0328L34.0683 28.2106L34.0683 25.9519C34.0683 21.2692 37.8644 17.4731 42.5471 17.4731C47.1791 17.4731 51.5219 18.7113 55.2627 20.8746L55.2663 20.8685L69.9518 29.3472C82.1178 36.3712 86.2861 51.9277 79.2621 64.0937C67.5555 84.3702 41.6279 91.3175 21.3514 79.6108C14.7648 75.8081 9.58472 70.5047 5.99364 64.382C13.0924 76.3343 28.5049 80.3864 40.5782 73.4158L55.3496 64.8876L55.3471 64.8834C59.0597 62.7268 62.2758 59.5999 64.5766 55.615Z'
          fill='url(#paint2_linear_230_632)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_230_632'
          x1='51.0099'
          y1='0.506226'
          x2='51.0099'
          y2='63.8145'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E3F3F0' />
          <stop offset='0.317708' stopColor='#1AB7FF' />
          <stop offset='0.661458' stopColor='#2075FC' />
          <stop offset='1' stopColor='#5355FD' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_230_632'
          x1='1.58674'
          y1='56.7653'
          x2='56.4133'
          y2='25.1112'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E3F3F0' />
          <stop offset='0.317708' stopColor='#1AB7FF' />
          <stop offset='0.661458' stopColor='#2075FC' />
          <stop offset='1' stopColor='#5355FD' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_230_632'
          x1='75.0228'
          y1='71.4365'
          x2='20.1962'
          y2='39.7824'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E3F3F0' />
          <stop offset='0.317708' stopColor='#1AB7FF' />
          <stop offset='0.661458' stopColor='#2075FC' />
          <stop offset='1' stopColor='#5355FD' />
        </linearGradient>
        <clipPath id='clip0_230_632'>
          <rect
            width='85'
            height='85'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
